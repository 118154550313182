<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body>
        <b-overlay
          :show="isLoading"
          rounded="sm"
        >
          <div>
            <b-row>
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  :line-numbers="true"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  @on-page-change="onPageChange"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{ filter.itemsPerPage * (filter.currentPage - 1) + 1 }}
                          đến {{ filter.itemsPerPage * (filter.currentPage) }} &nbsp; của {{ mainData.length }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BButton, BCard, BCol, BContainer, BFormSelect, BOverlay, BPagination, BRow
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: "DetailAbsentLogs",
  directives: {
  },
  components: {
    BButton,
    VueGoodTable,
    BRow,
    BCol,
    BCard,
    BContainer,
    BOverlay,
    BFormSelect,
    BPagination
  },
  props: {
    dataSend: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        creditClassId: this.dataSend.id || this.$route.params.id,
        isEligible: '',
        sort: '1_asc',
      },
      mainData: [],
      totalRows: 0
    }
  },
  computed: {
    ...mapGetters({
      turns: 'turn/turns',
      listSchedule: 'schedule/schedulers',
    }),
    statusFilterDropdownItems() {
      return this.statuses.map(item => ({ value: item.value, text: item.label }))
    },
    eligibleFilterDropdownItems() {
      return this.eligible.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: `Ngày nghỉ`,
          field: 'absentDate',
          sortable: false,
          thClass: 'text-center',
          dateInputFormat: 'dd/mm/yyyy',
          dateOutputFormat: 'dd/mm/yyyy'
        },
        {
          label: `Tiết vắng`,
          field: 'turnList',
          sortable: false,
          thClass: 'text-center',
        },
        {
          label: `Lý do`,
          field: 'reason',
          sortable: false,
          thClass: 'text-center',
        },
      ]
    }

  },
  watch: {
  },
  async created() {
      const data = await this.getAbsentTimeByStudentCreditClassId({student_credit_class_id:this.dataSend.id})
      const groupedData = data.reduce((acc, current) => {
        const formattedDate = this.formatDate(current.absentDate);
        const existing = acc.find(item => item.absentDate === formattedDate);
        if (existing) {
          existing.turnList += `,${current.turnList}`;
        } else {
          acc.push({
            ...current,
            absentDate: formattedDate
          });
        }
        return acc;
      }, []);
    groupedData.forEach(item => {
      item.turnList = this.sortTurnList(item.turnList);
    });

      groupedData.sort((a, b) => {
        const [dayA, monthA, yearA] = a.absentDate.split('/').map(Number);
        const [dayB, monthB, yearB] = b.absentDate.split('/').map(Number);
        return new Date(yearA, monthA - 1, dayA) - new Date(yearB, monthB - 1, dayB);
      });
      this.mainData = groupedData
  },
  methods: {
    ...mapActions({
      getTurns: 'turn/getTurns',
      getSchedulersByDayAndCreditClass: 'schedule/getSchedulersByDayAndCreditClass',
      getAbsentTimeByStudentCreditClassId: 'creditClassStudent/getAbsentTimeByStudentCreditClassId'
    }),

     formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = String(date.getUTCDate()).padStart(2, '0');
      const month = String(date.getUTCMonth() + 1).padStart(2, '0');
      const year = date.getUTCFullYear();
      return `${day}/${month}/${year}`
    },

    sortTurnList(turnList) {
      return turnList.split(',').map(Number).sort((a, b) => a - b).join(',');
    },

    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },

    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },

  },
}
</script>

<style scoped>

</style>
